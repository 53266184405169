<template>

</template>

<script>
  export default {
    async created() {
      try {
        var result = await this.$root.$store.state.account.userManager.signinRedirectCallback();
          var returnToUrl = '/';
        if (result.state !== undefined) { returnToUrl = result.state; }
        this.$router.push({ path: returnToUrl });
      } catch (e){
      }
    }
  }
</script>
